import React from "react";
import "./Theme/css/App.css";
import Index from "./Pages/Home/Home";


function App() {
  return (

        <Index />

  )
}

export default App;
